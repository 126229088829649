
























import { Component, Vue } from "vue-property-decorator";
import DxDateBox from "devextreme-vue/date-box";
import { API } from "@/shared/api";
import usuarioModule from "@/store/modules/usuario-module";
@Component({
  components: {
    DxDateBox,
    DocumentsHandler: () => import("../Documentos/DocumentsHandler.vue"),
  },
})
export default class InformeRepositorio extends Vue {
  async created() {
    await usuarioModule.getmiusuario();
  }
  public get_url() {
    
    if (usuarioModule.mi_usuario.id_cliente > 0) {
      return {
        url:
          API.RepositorioInformes + "/documents/" + usuarioModule.mi_usuario.id_cliente,
        url_download:
          API.webApiBaseRepositoriArchivos + usuarioModule.mi_usuario.id_cliente,
      };
    } else {
      return {
        url: "",
        url_download: "",
      };
    }
  }
}
