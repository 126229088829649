import { store } from "@/store/store";
import { Usuario } from "@/shared/dtos/usuario";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { changepwd } from "@/shared/dtos/changepwd";
import { UsuarioAllDto } from "@/shared/dtos/usuariosAllDto";
import { ssmPermissionService } from "@/shared/services/PermissionService";

@Module({
  namespaced: true,
  name: "usuarioModule",
  store,
  dynamic: true,
})
class usuarioModule extends VuexModule {
  public usuarios: UsuarioAllDto[] = [];
  public usuario: Usuario = new Usuario();
  public mi_usuario: Usuario = new Usuario();

  @Action({ commit: "onGetusuarios" })
  public async getusuarios() {
    return await ssmHttpService.get(API.usuario);
  }
  @Action({ commit: "onGetusuariosPermiso" })
  public async getusuariosPermiso() {
    return await ssmHttpService.get(API.usuario + "/permiso/");
  }

  @Action({ commit: "onGetusuarios" })
  public async getusuariosActivos() {
    return await ssmHttpService.get(API.usuario + "/activos/");
  }

  @Action({ commit: "onGetusuario" })
  public async getusuario(id: any) {
    return await ssmHttpService.get(API.usuario + "/" + id);
  }

  @Action({ commit: "onGetmi_usuario" })
  public async getmiusuario() {
    return await ssmHttpService.get(API.usuario + "/mi_user/");
  }

  @Action
  public async guardarusuario(usuario: Usuario) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.usuario, usuario.toJson());
  }

  @Action
  public async modificarusuario(usuario: Usuario) {
    return await ssmHttpService.put(API.usuario + "/" + usuario.id, usuario);
  }
  @Action
  public async modificarmi_usuario(usuario: Usuario) {
    return await ssmHttpService.put(
      API.usuario + "/" + usuario.id,
      usuario,
      true,
      false,
      true
    );
  }

  @Action
  public async change_pwdusuario(change: changepwd) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(
      API.usuario + "/change_pwd/",
      change.toJson(),
      true,
      false,
      true
    );
  }

  @Action
  public async eliminarusuario(usuario: Usuario) {
    await ssmHttpService.delete(API.usuario + "/" + usuario.id, null, false);
    this.getusuarios();
  }

  @Mutation
  public onGetusuarios(res: Usuario[]) {
    this.usuarios = res ? res.map((x) => new UsuarioAllDto(x)) : [];
  }

  @Mutation
  public onGetusuariosPermiso(res: string[]) {
    ssmPermissionService.setPermissions(res);
  }

  @Mutation
  public onGetusuario(res: Usuario) {
    this.usuario = new Usuario(res);
  }

  @Mutation
  public onGetmi_usuario(res: Usuario) {
    this.mi_usuario = new Usuario(res);
  }

}
export default getModule(usuarioModule);
